import * as React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"

export const query = graphql`
query {
    site {
        siteMetadata {
            title,
            description,
            domainName
        }   
    }
}
`;

// markup
const IndexPage = ({ data }) => {
  return (

    <div>
      <header>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
      </header>

      <div class="min-h-full">
        <div className="h-full bg-gray-900">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">
              <div className="w-full max-w-xl mb-12 xl:pr-16 xl:mb-0 xl:w-7/12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                  {data.site.siteMetadata.title}
                </h2>
                <p className="max-w-xl mb-4 text-base text-gray-200 md:text-lg">
                  {data.site.siteMetadata.description}
                </p>
              </div>
              <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
                <div className="relative">
                  <div className="relative bg-white rounded shadow-2xl p-7 sm:p-10">
                    <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                      Contact Us 
                    </h3>
                    <form method="post" action="https://getform.io/f/d92385c8-6ca9-49ed-8836-dc59678e1260">
                      <div class="mb-1 sm:mb-2">
                        <input name="DomainName" id="DomainName" type="hidden" value={data.site.siteMetadata.domainName} />
                        <label class="inline-block mb-1 font-medium">
                          Name
                          <input type="text" name="name" id="name" required class="bg-gray-200 border border-gray-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                          />
                        </label>
                        <label class="inline-block mb-1 font-medium">
                          Email
                          <input type="email" name="email" id="email" required class="bg-gray-200 border border-gray-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                          />
                        </label>
                        <label class="inline-block mb-1 font-medium">
                          Phone Number
                          <input type="text" name="phone_number" required id="phone_number" class="bg-gray-200 border border-gray-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                          />
                        </label>
                        <label class="inline-block mb-1 font-medium">
                          Any additional information (such as best time to contact you, timezone, ext., etc):
                          <textarea name="message" id="message" rows="5" class="bg-gray-200 border border-gray-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full"
                          />
                        </label>
                        <button type="submit" class="bg-green-200 border border-gray-200  inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide transition duration-200  rounded shadow-md bg-deep-purple-accent-200">Send</button>

                        <input type="reset" class="bg-red-200 border max-w-sm border-gray-200 inline-flex items-center justify-center w-full h-12 font-medium tracking-wide transition duration-200  rounded shadow-md bg-deep-purple-accent-200" value="Clear" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage
